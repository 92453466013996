import React, { useEffect, useMemo, useState } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import NumberFormat from 'react-number-format';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import { Select, InputLabel, MenuItem } from '@material-ui/core';
import { dialogStyles } from './styles';
import { isMode, modes } from './utils';
import UploadImageInput from 'components/UploadImageInput';
import { ELanguage } from 'domain/user/types';
import { NumberedList, BulletedList, TodoList } from '@yoopta/lists';
import YooptaEditor, {
  createYooptaEditor,
  YooptaContentValue,
  YooptaOnChangeOptions,
} from '@yoopta/editor';
import Paragraph from '@yoopta/paragraph';
import Blockquote from '@yoopta/blockquote';
import { html } from '@yoopta/exports';
import { HeadingOne, HeadingTwo, HeadingThree } from '@yoopta/headings';
import Link from '@yoopta/link';
import ActionMenuList, { DefaultActionMenuRender } from '@yoopta/action-menu-list';
import Toolbar, { DefaultToolbarRender } from '@yoopta/toolbar';
import LinkTool, { DefaultLinkToolRender } from '@yoopta/link-tool';
import Image from '@yoopta/image';
import _ from 'lodash';
import { FileService } from 'services/api/file';

const TOOLS = {
  ActionMenu: {
    render: DefaultActionMenuRender,
    tool: ActionMenuList,
  },
  Toolbar: {
    render: DefaultToolbarRender,
    tool: Toolbar,
  },
  LinkTool: {
    render: DefaultLinkToolRender,
    tool: LinkTool,
  },
};

const plugins = [
  Paragraph,
  Blockquote,
  Link,
  NumberedList,
  BulletedList,
  TodoList,
  HeadingOne,
  HeadingTwo,
  HeadingThree,
  Image.extend({
    options: {
      async onUpload(file) {
        const response = await FileService.uploadFile(file);

        return {
          src: response.data.uri,
          alt: response.data.url,
        };
      },
    },
  }),
];

const CreateOrUpdateNewsModal = ({ open, handleClose, onSubmit, mode, news }) => {
  const styles = dialogStyles();

  const initialValues = useMemo(
    () => ({
      title: news?.title ?? '',
      text: news?.text ?? '',
      imageURL: news?.imageURL ?? '',
      language: news?.language ?? 'en',
    }),
    [news],
  );

  const editor = useMemo(() => createYooptaEditor(), []);
  const [value, setValue] = useState<YooptaContentValue>();
  const onChange = (value: YooptaContentValue, options: YooptaOnChangeOptions) => {
    setValue(value);
  };

  const deserializeHTML = (htmlString: string) => {
    if (_.size(editor.plugins) === 0 && htmlString?.length > 0) {
      setTimeout(() => deserializeHTML(htmlString));
      return;
    }
    const content = html.deserialize(editor, htmlString);

    editor.setEditorValue(content);
    setValue(content);
  };

  useEffect(() => {
    if (news) deserializeHTML(news.text);
    else deserializeHTML('');
  }, [news, editor]);

  const serializeHTML = () => {
    const htmlString = html.serialize(editor, value!);
    return htmlString;
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        className={styles.dialog}
        PaperProps={{ className: styles.paper }}
      >
        <DialogTitle id="form-dialog-title">
          {isMode(mode, modes.update) ? 'Обновить' : 'Создать'} новость
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={Yup.object().shape({
              title: Yup.string().required('Введите название'),
              imageURL: Yup.string().required('Загрузите изображение'),
              language: Yup.string().required('Выберите язык'),
            })}
            onSubmit={(values) =>
              onSubmit({
                ...values,
                text: serializeHTML(),
              })
            }
            validateOnChange
            render={({
              values,
              errors,
              handleSubmit,
              handleChange,
              handleBlur,
              setFieldValue,
              touched,
            }) => {
              return (
                <form onSubmit={handleSubmit} className={styles.form}>
                  <FormControl component="fieldset" className={styles.formControl}>
                    <TextField
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={!!(errors.title && touched.title)}
                      name="title"
                      margin="dense"
                      label="Название"
                      value={values.title}
                      placeholder="Введите название"
                    />

                    <div style={{ color: 'red', fontSize: 12 }}>
                      {touched.title ? (errors.title as string) : ''}
                    </div>

                    <FormControl className={styles.formControl} size="small">
                      <YooptaEditor
                        editor={editor}
                        placeholder="Контент"
                        plugins={plugins}
                        tools={TOOLS}
                        value={value}
                        autoFocus
                        onChange={onChange}
                      />
                    </FormControl>

                    <FormControl className={styles.formControl} size="small">
                      <UploadImageInput
                        image={values.imageURL}
                        onChange={(value) => setFieldValue('imageURL', value)}
                      />
                      <div style={{ color: 'red', fontSize: 12 }}>
                        {touched.imageURL ? (errors.imageURL as string) : ''}
                      </div>
                    </FormControl>

                    <FormControl style={{ minWidth: 120, margin: '10px 0' }} size="small">
                      <InputLabel id="demo-select-small">Язык</InputLabel>
                      <Select
                        labelId="demo-select-small"
                        id="demo-select-small"
                        value={values.language}
                        label="Язык"
                        name="language"
                        onChange={handleChange}
                      >
                        <MenuItem value={ELanguage.EN}>Английский</MenuItem>
                        <MenuItem value={ELanguage.DE}>Немецкий</MenuItem>
                        <MenuItem value={ELanguage.FR}>Французский</MenuItem>
                        <MenuItem value={ELanguage.KK}>Казахский</MenuItem>
                        <MenuItem value={ELanguage.RU}>Русский</MenuItem>
                        <MenuItem value={ELanguage.UA}>Украинский</MenuItem>
                      </Select>
                    </FormControl>
                  </FormControl>

                  <DialogActions>
                    <Button color="secondary" variant="contained" onClick={() => handleClose()}>
                      Отмена
                    </Button>
                    <Button color="primary" variant="contained" onClick={() => handleSubmit()}>
                      {isMode(mode, modes.update) ? 'Обновить' : 'Создать'}
                    </Button>
                  </DialogActions>
                </form>
              );
            }}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CreateOrUpdateNewsModal;
