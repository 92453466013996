export enum EExternalGameProviderType {
  ALGNET = 0,
  EVENBET = 1,
}
export enum EExternalGameType {
  SLOTS = 1,
  LIVE_DEALER = 2,
  SPORTS_BETTING = 3,
  VIRTUAL_SPORT = 4,
}
