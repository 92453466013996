import endpoints from 'api/endpoints';
import { api } from 'api/rest';

export class NewsService {
  static async getAll() {
    const data = await api.get(endpoints.news);
    return data;
  }
  static async create(params) {
    const data = await api.post(endpoints.news, params);
    return data;
  }
  static async delete(id) {
    const data = await api.delete(`${endpoints.news}/${id}`);
    return data;
  }
  static async update(id, params) {
    const data = await api.post(`${endpoints.news}/${id}`, params);
    return data;
  }
}
