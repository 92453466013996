import endpoints from 'api/endpoints';
import { api } from 'api/rest';

export class TournamentScheduleService {
  static async findAll() {
    const response = await api.get(endpoints.tournamentSchedules);
    return response;
  }

  static async create(data: Record<string, any>) {
    const response = await api.post(endpoints.tournamentSchedules, data);
    return response;
  }

  static async getByID(id: string) {
    const response = await api.get(`${endpoints.tournamentSchedules}/${id}`);
    return response;
  }

  static async updateByID(id: string, data: Record<string, any>) {
    const response = await api.put(`${endpoints.tournamentSchedules}/${id}`, data);
    return response;
  }

  static async deleteByID(id: string) {
    const response = await api.delete(`${endpoints.tournamentSchedules}/${id}`);
    return response;
  }
}
