import { useEffect, useRef, useState } from 'react';

import moment from 'moment-timezone';
import { makeStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table';

import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import { errorService } from 'services/alert/services';

import CreateOrUpdateNewsModal from './create-or-update-news-modal';
import { modes } from './utils';
import { NewsService } from 'services/api/news';

const styles = {
  increaseBalance: {
    color: '#2eb92e',
  },
  decreaseBalance: {
    color: '#e01919',
  },
  takingReservedBalance: {
    color: '#707070',
  },
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0',
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: 300,
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
  },
};

const useStyles = makeStyles(styles);

const NewsPage = () => {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [openCreateNewsModal, setOpenCreateNewsModal] = useState(false);
  const [openUpdateNewsModal, setOpenUpdateNewsModal] = useState(false);
  const [selectedNews, setSelectedNews] = useState<any>(null);

  const tableRef = useRef();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await NewsService.getAll();
      setData(response.data.news);
    } catch (e) {
      errorService.sendError('Произошла ошибка' + e.message);
    }
  };

  const handleCreateModalClose = () => {
    setOpenCreateNewsModal(false);
  };

  const handleUpdateModalClose = () => {
    setOpenUpdateNewsModal(false);
    setSelectedNews(null);
  };

  const onRowDelete = async (oldData) => {
    try {
      await NewsService.delete(oldData._id);
      // @ts-ignore
      setData((news) => news.filter((t) => t._id !== oldData._id));
    } catch (e) {
      errorService.sendError('Произошла ошибка' + e.message);
    }
  };

  const onCreate = async (values) => {
    try {
      await NewsService.create(values);
      await fetchData();
      handleCreateModalClose();
    } catch (e) {
      errorService.sendError(e?.message);
    }
  };

  const onUpdate = async (newValues) => {
    try {
      await NewsService.update((selectedNews as any)?._id, newValues);
      handleUpdateModalClose();
      fetchData();
    } catch (e) {
      errorService.sendError(e.message);
    }
  };

  return (
    <div>
      {openCreateNewsModal && (
        // @ts-ignore
        <CreateOrUpdateNewsModal
          open={true}
          handleClose={handleCreateModalClose}
          onSubmit={onCreate}
          mode={modes.create}
        />
      )}
      {openUpdateNewsModal && (
        <CreateOrUpdateNewsModal
          open={true}
          handleClose={handleUpdateModalClose}
          onSubmit={onUpdate}
          news={selectedNews}
          mode={modes.update}
        />
      )}
      <GridContainer>
        {/* @ts-ignore */}
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <p className={classes.cardTitleWhite}>Управление новостями</p>
            </CardHeader>
            <CardBody>
              {/* @ts-ignore */}
              <MaterialTable
                title="Новости"
                tableRef={tableRef}
                actions={[
                  {
                    icon: 'add',
                    tooltip: 'Создать новость',
                    isFreeAction: true,
                    onClick: () => setOpenCreateNewsModal(true),
                  },
                  {
                    icon: 'edit',
                    tooltip: 'Обновить новость',
                    onClick: (_, rowData) => {
                      setSelectedNews(rowData);
                      setOpenUpdateNewsModal(true);
                    },
                  },
                ]}
                localization={{
                  body: {
                    deleteTooltip: 'Удалить',
                    editRow: {
                      deleteText: 'Вы уверены что хотите удалить новость?',
                    },
                  },
                  toolbar: { searchPlaceholder: 'Поиск' },
                  pagination: { labelRowsSelect: 'cтрок' },
                  header: {
                    actions: 'Действия',
                  },
                }}
                columns={[
                  {
                    title: 'ID',
                    field: '_id',
                  },
                  {
                    title: 'Название',
                    field: 'title',
                  },
                  {
                    title: 'Контент',
                    field: 'text',
                  },
                  {
                    title: 'Изображение',
                    field: 'imageURL',
                  },
                  {
                    title: 'Язык',
                    field: 'language',
                  },
                  {
                    title: 'Дата создания',
                    field: 'createdAt',
                    type: "datetime"
                  },
                ]}
                data={data}
                options={{
                  filtering: true,
                  // debounceInterval: 700,
                  search: false,
                }}
                editable={{
                  isEditable: () => true,
                  onRowAddCancelled: () => console.log('Row adding cancelled'),
                  onRowUpdateCancelled: () => console.log('Row editing cancelled'),
                  onRowDelete,
                }}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default NewsPage;
