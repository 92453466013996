/*!

=========================================================
* Material Dashboard React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import GamesIcon from '@material-ui/icons/GridOn';
import LangIcon from '@material-ui/icons/Language';
import RankIcon from '@material-ui/icons/Star';
import TuneIcon from '@material-ui/icons/Tune';
import LineWeightIcon from '@material-ui/icons/LineWeight';
import Dashboard from '@material-ui/icons/Dashboard';
import Person from '@material-ui/icons/Person';
import TransactionsIcon from '@material-ui/icons/Assignment';
import ContactSupport from '@material-ui/icons/ContactSupport';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import ViewCarouselIcon from '@material-ui/icons/ViewCarousel';
import SportsEsportsIcon from '@material-ui/icons/SportsEsports';
import MoodIcon from '@mui/icons-material/Mood';
import GradeIcon from '@mui/icons-material/Grade';
import SettingsIcon from '@mui/icons-material/Settings';
import GridViewIcon from '@mui/icons-material/GridView';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

// core components/views for Admin layout
import DashboardPage from 'views/Dashboard/Dashboard.js';
import Transactions from 'views/Transactions';
import Users from 'views/Users';
import Games from 'views/Games';
import Ranks from 'views/Ranks';
import Variables from 'views/Variables';
import RakeLevels from 'views/RakeLevels';
import Admins from 'views/Admins';
import Languages from 'views/Languages';
import Payments from 'views/Payments';
import { PaymentType, PaymentTransactionStatus } from 'constants/payment';
import Tournaments from 'views/Tournaments';
import Support from 'views/Support';
import PaymentMethods from 'views/PaymentMethods';
import PushNotifications from 'views/PushNotifications';
import Notifications from 'views/Notifications';
import Banners from 'views/Banners';
import StickerPacks from 'views/StickerPacks';
import ExternalGameProviders from 'views/ExternalGameProviders';
import RatingHistories from 'views/RatingHistory';
import Configs from 'views/Configs';
import StaticBlockPage from 'views/StaticBlocks';
import ActiveGames from 'views/ActiveGames';
import SystemAvatars from 'views/SystemAvatars';
import UsersStatistics from 'views/UsersStatistics';
import ExternalGames from './views/ExternalGames';
import ReferralPrograms from './views/ReferralPrograms';
import TournamentTemplates from 'views/TournamentTemplates';
import TournamentSchedules from 'views/TournamentSchedules';
import NewsPage from 'views/News';

const dashboardRoutes = [
  {
    path: '/dashboard',
    route: '/dashboard',
    name: 'Статистика',
    icon: Dashboard,
    component: DashboardPage,
    layout: '/admin',
  },
  {
    path: '/users',
    route: '/users',
    name: 'Пользователи',
    icon: Person,
    component: Users,
    layout: '/admin',
  },
  {
    path: '/admins',
    route: '/admins',
    name: 'Администраторы',
    icon: Person,
    component: Admins,
    layout: '/admin',
  },
  {
    path: '/transactions/0',
    route: '/transactions/:userId',
    name: 'Транзакции',
    icon: TransactionsIcon,
    component: Transactions,
    layout: '/admin',
  },
  {
    path: '/statistics',
    route: '/statistics',
    name: 'Статистика',
    icon: TransactionsIcon,
    component: UsersStatistics,
    layout: '/admin',
  },
  {
    path: '/rating-histories/0',
    route: '/rating-histories/:userId',
    name: 'История рейтинга',
    icon: GradeIcon,
    component: RatingHistories,
    layout: '/admin',
  },

  {
    path: '/games/0',
    route: '/games/:userId',
    name: 'Игры',
    icon: GamesIcon,
    component: Games,
    layout: '/admin',
  },
  {
    path: '/active-games',
    route: '/active-games',
    name: 'Активные Игры',
    icon: GamesIcon,
    component: ActiveGames,
    layout: '/admin',
  },
  {
    path: '/tournaments',
    route: '/tournaments',
    name: 'Турниры',
    icon: GamesIcon,
    component: Tournaments,
    layout: '/admin',
  },
  {
    path: '/templates',
    route: '/templates',
    name: 'Турнирные шаблоны',
    icon: GamesIcon,
    component: TournamentTemplates,
    layout: '/admin',
  },
  {
    path: '/schedules',
    route: '/schedules',
    name: 'Турнирные планировщики',
    icon: GamesIcon,
    component: TournamentSchedules,
    layout: '/admin',
  },
  {
    path: '/payments',
    route: '/payments',
    name: 'Платежи',
    icon: TransactionsIcon,
    component: () => <Payments title={'Платежи'} type={PaymentType.topUp} />,
    layout: '/admin',
  },
  {
    path: '/payouts',
    route: '/payouts',
    name: 'Выплаты',
    icon: TransactionsIcon,
    component: () => <Payments title="Выплаты" type={PaymentType.withdraw} />,
    layout: '/admin',
  },
  {
    path: '/requests',
    route: '/requests',
    name: 'Запросы',
    icon: TransactionsIcon,
    component: () => <Payments title={'Запросы на выплаты'} type={PaymentType.withdrawRequest} />,
    layout: '/admin',
  },
  {
    path: '/payment-methods',
    route: '/payment-methods',
    name: 'Способы оплаты',
    icon: TransactionsIcon,
    component: PaymentMethods,
    layout: '/admin',
  },
  {
    path: '/support',
    route: '/support',
    name: 'Саппорт',
    icon: ContactSupport,
    component: Support,
    layout: '/admin',
  },
  {
    path: '/push-notifications',
    route: '/push-notifications',
    name: 'Пуш уведомления',
    icon: NotificationsActiveIcon,
    component: PushNotifications,
    layout: '/admin',
  },
  {
    path: '/notifications',
    route: '/notifications',
    name: 'Уведомления',
    icon: NotificationsNoneIcon,
    component: Notifications,
    layout: '/admin',
  },
  {
    path: '/referral-programs',
    route: '/referral-programs',
    name: 'Реферальные пр.',
    icon: TransactionsIcon,
    component: ReferralPrograms,
    layout: '/admin',
  },
  {
    path: '/banners',
    route: '/banners',
    name: 'Баннеры',
    icon: ViewCarouselIcon,
    component: Banners,
    layout: '/admin',
  },
  {
    path: '/external-games',
    route: '/external-games',
    name: 'Внешние игры',
    icon: SportsEsportsIcon,
    component: ExternalGames,
    layout: '/admin',
  },
  {
    path: '/external-game-providers',
    route: '/external-game-providers',
    name: 'Провайдеры',
    icon: SportsEsportsIcon,
    component: ExternalGameProviders,
    layout: '/admin',
  },
  {
    path: '/news',
    route: '/news',
    name: 'Новости',
    icon: SportsEsportsIcon,
    component: NewsPage,
    layout: '/admin',
  },
  {
    path: '/sticker-packs',
    route: '/sticker-packs',
    name: 'Стикер паки',
    icon: MoodIcon,
    component: StickerPacks,
    layout: '/admin',
  },
  {
    path: '/static-blocks',
    route: '/static-blocks',
    name: 'CMS Блоки',
    icon: GridViewIcon,
    component: StaticBlockPage,
    layout: '/admin',
  },
  {
    path: '/ranks',
    route: '/ranks',
    name: 'Ранги',
    icon: RankIcon,
    component: Ranks,
    layout: '/admin',
  },

  {
    path: '/rake-levels',
    route: '/rake-levels',
    name: 'Уровни рейка',
    icon: LineWeightIcon,
    component: RakeLevels,
    layout: '/admin',
  },
  {
    path: '/configs',
    route: '/configs',
    name: 'Конфиги',
    icon: SettingsIcon,
    component: Configs,
    layout: '/admin',
  },
  {
    path: '/variables',
    route: '/variables',
    name: 'Настройки',
    icon: TuneIcon,
    component: Variables,
    layout: '/admin',
  },
  {
    path: '/system-avatars',
    route: '/system-avatars',
    name: 'Аватарки',
    icon: AccountCircleIcon,
    component: SystemAvatars,
    layout: '/admin',
  },

  {
    path: '/languages',
    route: '/languages',
    name: 'Языки',
    icon: LangIcon,
    component: Languages,
    layout: '/admin',
  },
];

export default dashboardRoutes;
