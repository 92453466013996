import React, { useEffect, useMemo, useState } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import NumberFormat from 'react-number-format';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import { Select, InputLabel, MenuItem } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { BeatLoader } from 'react-spinners';
import ErrorIcon from '@mui/icons-material/Error';
import { Typography } from '@mui/material';

import { errorService } from 'services/alert/services';
import { useRanks } from 'hooks/use-ranks';

import { dialogStyles } from './styles';
import { tournamentType, isType, isMode, modes } from './utils';
import { ETournamentType } from 'domain/tournament';
import { TournamentService } from 'services/api/tournament';
import { TournamentTemplateService } from 'services/api/tournament-template';
import { TimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import UploadImage from 'views/Banners/components/UploadImage';
import { FileService } from 'services/api/file';
import UploadImageInput from 'components/UploadImageInput';
import { EGameFen } from 'domain/game/game.types';

const availableValues = [1, 2, 4, 8, 16, 32, 64, 128, 256, 512, 1024];

const defaultPrizes = [
  {
    amount: 0,
    rating: 0,
    place: 1,
    range: null,
  },
];

const colors = [
  {
    value: 'darkGreen',
    label: 'Dark Green',
  },
  {
    value: 'darkViolet',
    label: 'Dark Violet',
  },
  {
    value: 'darkBlue',
    label: 'Dark Blue',
  },
  {
    value: 'darkGray',
    label: 'Dark Gray',
  },
  {
    value: 'darkPurple',
    label: 'Dark Purple',
  },
];

export const staticYearMonthDay = '2022-12-18';

const NumberFormatCustom = (props) => {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: +values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
    />
  );
};

const timeControlList = [
  {
    time: 1,
    increment: 1,
    label: '1 + 1',
  },
  {
    time: 3,
    increment: 3,
    label: '3 + 3',
  },
  {
    time: 5,
    increment: 5,
    label: '5 + 5',
  },
  {
    time: 10,
    increment: 0,
    label: '10 + 0',
  },
  {
    time: 20,
    increment: 0,
    label: '20 + 0',
  },
];

const defaultTimeControl = { time: 5, increment: 5 };

const ErrorMessage = ({ message }) => {
  return <div style={{ color: 'red', fontSize: 12 }}>{message}</div>;
};

const CreateOrUpdateTournamentModal = ({ open, handleClose, onSubmit, mode, tournamentId }) => {
  const styles = dialogStyles();
  const { data: ranks, isLoading, isError } = useRanks();
  const [template, setTemplate] = useState<any>(null);

  useEffect(() => {
    if (tournamentId) {
      getTournament();
    }

    return () => {
      setTemplate(null);
    };
  }, [tournamentId]);

  const getTournament = async () => {
    try {
      const response = await TournamentTemplateService.getByID(tournamentId);
      setTemplate(response.data.template);
    } catch (e) {
      errorService.sendError(e.message);
    }
  };

  const initialValues = useMemo(
    () => ({
      title: template?.title ?? '',
      description: template?.description ?? '',
      imageURL: template?.imageURL ?? '',
      prizes: template?.prizes ?? defaultPrizes,
      buyIn: template?.buyIn ?? 0,
      maxPlayers: template?.maxPlayers ?? 2,
      maxPlayersRestrict: template?.maxPlayersRestrict ?? false,
      matchCount: template?.gameSettings?.matchCount ?? 1,
      fenType: template?.gameSettings?.fenType ?? EGameFen.FEN_NORMAL,
      type: template?.type ?? ETournamentType.PLAY_OFF,
      fromRank: template?.fromRank ?? '',
      startDayOffset: template?.startDayOffset ?? 0,
      startSecondsOffset: template?.startSecondsOffset ?? 0,
      time: template?.gameSettings?.time ?? defaultTimeControl.time,
      registrationDayOffset: template?.registrationDayOffset ?? null,
      registrationSecondsOffset: template?.registrationSecondsOffset ?? null,
    }),
    [template],
  );

  const handleAddOnePrize = (prizes, setFieldValue) => {
    const lastPrize = prizes[prizes.length - 1];
    const newPrize = {
      amount: 0,
      rating: 0,
      place: lastPrize.place === 4 || lastPrize.place == null ? null : lastPrize.place + 1,
      range:
        lastPrize.place === 4
          ? [4, 8]
          : lastPrize.place == null
          ? lastPrize.range.map((r) => r * 2)
          : null,
    };
    setFieldValue('prizes', [...prizes, newPrize]);
  };

  const handleDeleteOnePrize = (prizes, setFieldValue) => {
    const lastPrize = prizes[prizes.length - 1];
    if (lastPrize.place !== 1) {
      setFieldValue('prizes', [...prizes.slice(0, -1)]);
    } else {
      errorService.sendError(`Нельзя удалить приз за первое место`);
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        className={styles.dialog}
        PaperProps={{ className: styles.paper }}
      >
        <DialogTitle id="form-dialog-title">
          {isMode(mode, modes.update) ? 'Обновить' : 'Создать'} шаблон
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={Yup.object().shape({
              title: Yup.string().required('Введите название'),
              description: Yup.string().required('Введите описание'),
              buyIn: Yup.number().required('Введите Сумму buyIn'),
              prizes: Yup.array().of(
                Yup.object().shape({
                  amount: Yup.number('Должно быть числом').required('Введите сумму приза'),
                  place: Yup.number('Должно быть числом').nullable(true),
                  range: Yup.array().of(Yup.number('Must be number')).nullable(true),
                }),
              ),
              matchCount: Yup.number().min(1, 'Минимум - 1').required('Обязательное поле'),
              maxPlayers: Yup.number()
                .min(2, 'Минимальное количество участников -> 2')
                .required('Введите количество игроков')
                .test(
                  'maxPlayers',
                  'Количество игроков должно быть кратно 2',
                  (value) => !!availableValues.find((el) => el === +value),
                ),
              startDayOffset: Yup.number(),
              startSecondsOffset: Yup.number().required('Введите дату турнира'),
              time: Yup.number('Время должно быть числом').required('Введите время игр'),
              type: Yup.string().required('Введите тип турнира'),
              fromRank: Yup.string(),
              registrationDayOffset: Yup.number().nullable(true),
              registrationSecondsOffset: Yup.number().nullable(true),
            })}
            onSubmit={(values) => onSubmit(values, initialValues)}
            validateOnChange
            render={({
              values,
              errors,
              handleSubmit,
              handleChange,
              handleBlur,
              setFieldValue,
              touched,
            }) => {
              return (
                <form onSubmit={handleSubmit} className={styles.form}>
                  <FormControl component="fieldset" className={styles.formControl}>
                    <TextField
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={!!(errors.title && touched.title)}
                      name="title"
                      margin="dense"
                      label="Название"
                      value={values.title}
                      placeholder="Введите название"
                    />

                    <div style={{ color: 'red', fontSize: 12 }}>
                      {touched.title ? (errors.title as string) : ''}
                    </div>

                    <TextField
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={!!(errors.description && touched.description)}
                      name="description"
                      margin="dense"
                      label="Описание"
                      value={values.description}
                      placeholder="Введите Описание"
                    />
                    <div style={{ color: 'red', fontSize: 12 }}>
                      {touched.description ? (errors.description as string) : ''}
                    </div>

                    <FormControl className={styles.formControl} size="small">
                      <UploadImageInput
                        image={values.imageURL}
                        onChange={(value) => setFieldValue('imageURL', value)}
                      />
                      <div style={{ color: 'red', fontSize: 12 }}>
                        {touched.imageURL ? (errors.imageURL as string) : ''}
                      </div>
                    </FormControl>

                    <FormControl style={{ minWidth: 120, margin: '10px 0' }} size="small">
                      <InputLabel id="demo-select-small">Время игр</InputLabel>
                      <Select
                        labelId="demo-select-small"
                        id="demo-select-small"
                        value={values.time}
                        label="Время"
                        name="time"
                        onChange={handleChange}
                      >
                        {timeControlList.map((timeControl) => {
                          return (
                            <MenuItem value={timeControl.time} key={timeControl.time}>
                              {timeControl.label}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>

                    <FormControl style={{ minWidth: 120, margin: '10px 0' }} size="small">
                      <InputLabel id="demo-select-small">FEN</InputLabel>
                      <Select
                        labelId="demo-select-small"
                        id="demo-select-small"
                        value={values.fenType}
                        label="FEN"
                        name="fenType"
                        onChange={handleChange}
                      >
                        <MenuItem value={EGameFen.FEN_NORMAL}>Классический</MenuItem>
                        <MenuItem value={EGameFen.FEN_960}>960</MenuItem>
                      </Select>
                    </FormControl>

                    <FormControl style={{ minWidth: 120, margin: '10px 0' }} size="small">
                      <InputLabel id="demo-select-small">Тип турнира</InputLabel>
                      <Select
                        labelId="demo-select-small"
                        id="demo-select-small"
                        value={values.type}
                        label="Тип"
                        name="type"
                        onChange={handleChange}
                      >
                        <MenuItem value={ETournamentType.PLAY_OFF}>PLAY OFF</MenuItem>
                        <MenuItem value={ETournamentType.PLAY_OFF_LONG}>PLAY OFF LONG</MenuItem>
                        <MenuItem value={ETournamentType.LEAGUE}>LEAGUE</MenuItem>
                      </Select>
                    </FormControl>

                    <FormControl style={{ minWidth: 120, margin: '10px 0' }} size="small">
                      <InputLabel id="demo-select-small">Ранг</InputLabel>
                      <Select
                        labelId="demo-select-small"
                        id="demo-select-small"
                        value={values.fromRank}
                        label="Ранг"
                        name="fromRank"
                        onChange={handleChange}
                        displayEmpty
                        error={touched.fromRank ? Boolean(errors.fromRank) : false}
                      >
                        {isLoading && (
                          <div className={styles.rankIcon}>
                            <BeatLoader size={16} color="#00acc1" loading={true} />
                          </div>
                        )}
                        {isError && (
                          <div className={styles.rankIcon}>
                            <ErrorIcon className={styles.errorIcon} />
                          </div>
                        )}
                        {ranks &&
                          ranks.map((rank) => (
                            <MenuItem key={rank._id} value={rank._id}>
                              {rank.title}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>

                    <>
                      <TextField
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!(errors.maxPlayers && touched.maxPlayers)}
                        name="maxPlayers"
                        margin="dense"
                        label="Количество игроков"
                        value={values.maxPlayers}
                        placeholder="Введите количество"
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                        }}
                      />
                      <div style={{ color: 'red', fontSize: 12 }}>
                        {touched.maxPlayers ? (errors.maxPlayers as string) : ''}
                      </div>
                    </>

                    <TextField
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={!!(errors.buyIn && touched.buyIn)}
                      name="buyIn"
                      margin="dense"
                      label="Buy in"
                      value={values.buyIn}
                      placeholder="Введите buy in"
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                      }}
                    />
                    <div style={{ color: 'red', fontSize: 12 }}>
                      {touched.buyIn ? (errors.buyIn as string) : ''}
                    </div>

                    <TextField
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={!!(errors.matchCount && touched.matchCount)}
                      name="matchCount"
                      margin="dense"
                      label="Серия игр"
                      value={values.matchCount}
                      placeholder="Серия игр"
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                      }}
                    />

                    <div style={{ color: 'red', fontSize: 12 }}>
                      {(errors.matchCount as string) || ''}
                    </div>

                    <div
                      style={{
                        border: '3px solid gray',
                        display: 'flex',
                        flexDirection: 'column',
                        margin: '15px 0',
                        padding: '10px 5px',
                      }}
                    >
                      {values.prizes.map((prize, i) => {
                        return (
                          <div
                            style={{
                              display: 'grid',
                              gridTemplateColumns: '1fr 1fr',
                              gridColumnGap: '5px',
                            }}
                          >
                            <TextField
                              key={i}
                              onChange={handleChange}
                              name={`prizes[${i}].amount`}
                              margin="dense"
                              label={`Приз за ${
                                prize.place || `${prize.range[0]}-${prize.range[1]}`
                              } место`}
                              value={values.prizes[i].amount}
                              placeholder="Введите приз"
                              InputProps={{
                                inputComponent: NumberFormatCustom,
                              }}
                            />
                            <TextField
                              key={i}
                              onChange={handleChange}
                              name={`prizes[${i}].rating`}
                              margin="dense"
                              label={`Рейтинг за ${
                                prize.place || `${prize.range[0]}-${prize.range[1]}`
                              } место`}
                              value={values.prizes[i].rating}
                              placeholder="Введите рейтинг"
                              InputProps={{
                                inputComponent: NumberFormatCustom,
                              }}
                            />
                          </div>
                        );
                      })}
                      <div style={{ color: 'red', fontSize: 12 }}>
                        {Array.isArray(errors.prizes) &&
                          errors.prizes.filter(Boolean).map((err: Record<string, any>, i) => {
                            return <span key={i}>{err.amount || err.place}</span>;
                          })}
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-around',
                        }}
                      >
                        <Button
                          variant="contained"
                          onClick={() => {
                            handleAddOnePrize(values.prizes, setFieldValue);
                          }}
                        >
                          <AddIcon />
                        </Button>
                        <Button
                          variant="contained"
                          onClick={() => {
                            handleDeleteOnePrize(values.prizes, setFieldValue);
                          }}
                        >
                          <RemoveIcon />
                        </Button>
                      </div>
                    </div>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={Boolean(values.maxPlayersRestrict)}
                          name="maxPlayersRestrict"
                          onChange={handleChange}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      }
                      label={<span className={styles.formControlLabel}>Ограничение по кол. игроков?</span>}
                    />
                    <TextField
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={!!(errors.startDayOffset && touched.startDayOffset)}
                      name="startDayOffset"
                      margin="dense"
                      label="Смещение дней"
                      value={values.startDayOffset}
                      placeholder="Смещение дней"
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                      }}
                    />
                    <FormControl
                      style={{ minWidth: 120, width: '100%', margin: '10px 0 10px' }}
                      size="small"
                    >
                      <TimePicker
                        label="Дата начала"
                        value={dayjs(staticYearMonthDay)
                          .startOf('day')
                          .add(values.startSecondsOffset, 'seconds')}
                        onChange={(newValue) => {
                          const startDay = dayjs(newValue).startOf('day');
                          const difference = dayjs(newValue).diff(startDay, 'seconds');
                          setFieldValue('startSecondsOffset', difference);
                        }}
                      />
                      <ErrorMessage
                        message={touched.startSecondsOffset ? errors.startSecondsOffset : ''}
                      />
                    </FormControl>

                    <TextField
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={!!(errors.registrationDayOffset && touched.registrationDayOffset)}
                      name="registrationDayOffset"
                      margin="dense"
                      label="Смещение дней для регистрации"
                      value={values.registrationDayOffset}
                      placeholder="Смещение дней для регистрации"
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                      }}
                    />
                    <FormControl
                      style={{ minWidth: 120, width: '100%', margin: '10px 0 10px' }}
                      size="small"
                    >
                      <TimePicker
                        label="Дата начала регистрации"
                        value={
                          values.registrationSecondsOffset
                            ? dayjs(staticYearMonthDay)
                                .startOf('day')
                                .add(values.registrationSecondsOffset, 'seconds')
                            : null
                        }
                        onChange={(newValue) => {
                          const startDay = dayjs(newValue).startOf('day');
                          const newDate = dayjs(newValue);
                          if (newDate.isValid()) {
                            const difference = newDate.diff(startDay, 'seconds');
                            setFieldValue('registrationSecondsOffset', difference);
                          } else {
                            setFieldValue('registrationSecondsOffset', null);
                          }
                        }}
                      />
                      <ErrorMessage
                        message={
                          touched.registrationSecondsOffset ? errors.registrationSecondsOffset : ''
                        }
                      />
                    </FormControl>
                  </FormControl>

                  <DialogActions>
                    <Button color="secondary" variant="contained" onClick={() => handleClose()}>
                      Отмена
                    </Button>
                    <Button color="primary" variant="contained" onClick={() => handleSubmit()}>
                      {isMode(mode, modes.update) ? 'Обновить' : 'Создать'}
                    </Button>
                  </DialogActions>
                </form>
              );
            }}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CreateOrUpdateTournamentModal;
