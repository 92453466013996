import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Box } from '@material-ui/core';
import * as Yup from 'yup';
import { makeStyles } from '@material-ui/core/styles';
import { BeatLoader } from 'react-spinners';
import MaterialTable from 'material-table';

import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import { useAPI } from 'hooks/use-api';
import { usePostAPI } from 'hooks/use-post-api';
import { usePutAPI } from 'hooks/use-put-api';
import { UserProfileDialog } from 'components/UserProfile';
import { errorService, infoService, warnService } from 'services/alert/services';
import endpoints from 'api/endpoints';
import { AdminService } from 'services/api/admin';

import AddAdminDialog from './add-admin-dialog';

const styles = {
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0',
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
  },
};

const useStyles = makeStyles(styles);

const nicknameSchema = Yup.object().shape({
  nickname: Yup.string()
    .trim()
    .matches(
      /^[\w-=+*!$:%^.,()]+$/,
      'Никнейм может состоять из русских/английских букв, цифр и спец. символов (-_=+*!$:%^.,())!',
    )
    .min(2, 'Никнейм должен быть больше 2 символов!')
    .max(25, 'Никнейм очень длинный (макс. 25 символов)!')
    .required('Введите Никнейм'),
});

const emailSchema = Yup.object().shape({
  email: Yup.string()
    .trim()
    .matches(/^[^а-яА-Я]+$/, 'Используйте только английские буквы!')
    .max(64, 'Слишком длинный почтовый ящик!')
    .required('Введите почтовый ящик')
    .email('Почтовый ящик должен быть в формате name@domain.com!'),
});

const passwordSchema = Yup.object().shape({
  password: Yup.string()
    .trim()
    .matches(/^[^а-яА-Я]+$/, 'Используйте только английские буквы!')
    .min(8, 'Пароль должен быть больше 8 символов!')
    .max(16, 'Пароль очень длинный (макс. 16 символа)!')
    .required('Введите пароль'),
});

function validateField(schema, data) {
  try {
    schema.validateSync(data);
  } catch (error) {
    return error.message;
  }
  return true;
}

function Users() {
  const [userList, setUserList] = useState();
  const [currentUser, setCurrentUser] = useState();

  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [openAddDialog, setOpenAddDialog] = useState(false);

  const [{ data, isLoading, isError }, doGetAllUsers] = useAPI();
  const [
    { data: createUserData, isLoading: isCreateUserLoading, isError: isCreateUserError },
    doCreateUser,
  ] = usePostAPI({});
  const [
    { data: updateUserData, isLoading: isUpdateUserLoading, isError: isUpdateUserError },
    doUpdateUser,
  ] = usePutAPI({});

  useEffect(() => {
    if (data) setUserList(data.users);
  }, [data]);

  useEffect(() => {
    doGetAllUsers(endpoints.admins);
  }, []);

  useEffect(() => {
    if (isError || isCreateUserError || isUpdateUserError)
      errorService.sendError('Unknown Error API!');
  }, [isError, isCreateUserError, isUpdateUserError]);

  useEffect(() => {
    if (isCreateUserError)
      errorService.sendError(
        'Невозможно создать администратора: ' + createUserData.response.message,
      );
  }, [isCreateUserError]);

  useEffect(() => {
    if (isUpdateUserError)
      errorService.sendError(
        'Невозможно изменить администратора: ' + updateUserData.response.message,
      );
  }, [isUpdateUserError]);

  const getAdmins = async () => {
    try {
      const response = await AdminService.getAdmins();
      setUserList(response.data.users);
    } catch (e) {
      errorService.sendError(`Unknown Error API! ${e.message}`);
    }
  };

  function onRowAdd(newData) {
    return new Promise((resolve, reject) => {
      doCreateUser({
        url: endpoints.users,
        params: { ...newData, isAdmin: true },
        onSuccess: ({ _id }) => {
          setUserList((userList) => [{ ...newData, _id }, ...userList]);
          infoService.sendInfo('Администратор был успешно создан!');
          resolve();
        },
        onError: () => {
          reject();
        },
      });
    });
  }

  function onRowUpdate(newData, oldData) {
    return new Promise((resolve, reject) => {
      const diff = Object.keys(oldData)
        .filter((o) => o !== 'tableData')
        .reduce((acc, key) => {
          if (oldData[key] !== newData[key]) {
            acc[key] = newData[key];
          }
          return acc;
        }, {});
      if (Object.keys(diff).length === 0) return resolve();
      doUpdateUser({
        url: `${endpoints.users}/${oldData._id}`,
        params: diff,
        onSuccess: () => {
          setUserList((userList) =>
            userList.map((user) => (user._id === newData._id ? { ...newData } : user)),
          );
          infoService.sendInfo('Администратор был успешно изменен!');

          resolve();
        },
        onError: () => {
          reject();
        },
      });
    });
  }

  function onRowDelete(oldData) {
    return new Promise((resolve, reject) => {
      doUpdateUser({
        url: `${endpoints.users}/${oldData._id}`,
        params: { deleted: true },
        onSuccess: () => {
          setUserList((userList) => userList.filter((user) => user._id !== oldData._id));
          warnService.sendWarn('Администратор был успешно удален!');
          resolve();
        },
        onError: () => {
          reject();
        },
      });
    });
  }

  function onUserClickHandler(event, rowData) {
    return new Promise((resolve, reject) => {
      doUpdateUser({
        url: `${endpoints.users}/${rowData._id}`,
        params: { blocked: !rowData.blocked },
        onSuccess: () => {
          setUserList((userList) =>
            userList.map((user) =>
              user._id === rowData._id ? { ...rowData, blocked: !rowData.blocked } : user,
            ),
          );

          warnService.sendWarn(
            `Администратор был успешно ${rowData.blocked ? 'разблокирован' : 'заблокирован'}!`,
          );

          resolve();
        },
        onError: () => {
          reject();
        },
      });
    });
  }

  const onAddSupportHandler = async (_, rowData) => {
    try {
      await AdminService.addSupport(rowData._id);
      getAdmins();
    } catch (e) {
      errorService.sendError(`Произошла ошибка ${e?.response?.data?.message ?? e.message}`);
    }
  };

  const classes = useStyles();
  return (
    <div>
      <UserProfileDialog
        id={currentUser?._id}
        open={openCreateDialog}
        onHandleClose={(transaction) => {
          if (transaction) {
            setUserList([transaction, ...data]);
          }
          setOpenCreateDialog(false);
        }}
      />

      <AddAdminDialog
        open={openAddDialog}
        onHandleClose={() => {
          setOpenAddDialog(false);
        }}
        onSuccess={() => {
          getAdmins();
        }}
      />

      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <p className={classes.cardTitleWhite}>Управление администраторскими аккаунтами</p>
            </CardHeader>
            <CardBody>
              {isLoading ? (
                <Box textAlign="center">
                  <BeatLoader size={16} color="#00acc1" loading={true} />
                </Box>
              ) : (
                <MaterialTable
                  title="Администраторы "
                  onRowClick={(e, rowData) => {
                    setCurrentUser(rowData);
                    setOpenCreateDialog(true);
                  }}
                  localization={{
                    body: {
                      editTooltip: 'Редактировать',
                      deleteTooltip: 'Удалить',
                      editRow: {
                        deleteText: 'Вы уверены что хотите удалить пользователя?',
                      },
                    },
                    toolbar: { searchPlaceholder: 'Поиск' },
                    pagination: { labelRowsSelect: 'cтрок' },
                    header: {
                      actions: 'Действия',
                    },
                  }}
                  actions={[
                    {
                      icon: 'add',
                      tooltip: 'Добавить админа',
                      isFreeAction: true,
                      onClick: () => setOpenAddDialog(true),
                    },
                    {
                      icon: 'block',
                      tooltip: 'Блокировка пользователя.',
                      onClick: onUserClickHandler,
                    },
                    {
                      icon: 'help',
                      tooltip: 'Сделать саппортом.',
                      onClick: onAddSupportHandler,
                    },
                  ]}
                  columns={[
                    {
                      title: 'Никнейм',
                      field: 'nickname',
                      validate: (rowData) =>
                        validateField(nicknameSchema, {
                          nickname: rowData.nickname,
                        }),

                      render: (rowData) => rowData.nickname,
                    },
                    {
                      title: 'Почта',
                      field: 'email',
                      validate: (rowData) => validateField(emailSchema, { email: rowData.email }),
                    },
                    {
                      title: 'Пароль',
                      field: 'password',
                      validate: (rowData) =>
                        rowData.password === '***'
                          ? true
                          : validateField(passwordSchema, {
                              password: rowData.password,
                            }),
                      render: () => <div>***</div>,
                    },
                    {
                      title: 'Регистрация',
                      field: 'registered',
                      type: 'date',
                      editable: false,
                      render: (rowData) => moment(rowData.registered).format('HH:MM:ss DD-MM-YYYY'),
                    },
                  ]}
                  data={userList}
                  options={{
                    filtering: true,
                    rowStyle: (rowData) =>
                      rowData.blocked
                        ? { backgroundColor: 'pink' }
                        : rowData.isSupport
                        ? { backgroundColor: '#03fc56' }
                        : undefined,
                  }}
                  editable={{
                    isEditable: () => true,
                    onRowAddCancelled: () => console.log('Row adding cancelled'),
                    onRowUpdateCancelled: () => console.log('Row editing cancelled'),
                    onRowAdd,
                    onRowUpdate,
                    onRowDelete,
                  }}
                />
              )}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}

export default Users;
